import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {prism as PrismStyleLight} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {materialDark as PrismStyleDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkDirective from 'remark-directive';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import Directive from '../../customRemarkPlugins/directive';
import Spoiler from '../../customRemarkPlugins/spoiler';
import Heading from "../../customRemarkPlugins/heading";
import HexColor from "../../customRemarkPlugins/hexColor";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SRLWrapper } from "simple-react-lightbox";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import {themes} from "../../contexts/ThemeContext";

const notify = new Notyf({
    duration: 5000,
    position: {x: 'right', y: 'top'},
    dismissible: true,
    ripple: true
});

let hostname = window.location.hostname;

const Wiki = ({theme}) => {
    const [post, setPost] = useState('');
    const [isCopied, setCopied] = useState(false);
    const [pageFound, setPageFound] = useState(false);
    const [siteConfig, setSiteConfig] = useState([]);
    const [siteConfigFound, setSiteConfigFound] = useState(false);
    const [wikiConfig, setWikiConfig] = useState([]);
    const [wikiConfigFound, setWikiConfigFound] = useState(false);
    const [subMenuSearch, setSubMenuSearch] = useState('');

    let locations;
    let filename;
    let siteUrl = window.location.origin+"/";

    let wikiConfigName = hostname+"/wiki/wiki.json";
    let siteConfigName = hostname+"/config.json";

    const LoadPageContent = () => {
        const location = useLocation().pathname
            .replace("/wiki", "")
            .replace("/", "")
            .toLowerCase();
        if (location === ""){
            window.location.href = siteUrl+"wiki/overview";
        }

        locations = location.split("/");
        if (locations.length === 2){
            if (locations[1].length === 0){
                filename = hostname+"/wiki/"+locations[0].replace("/", "")+"/index.md";
            }else{
                filename = hostname+"/wiki/"+locations[0].replace("/", "")+"/"+locations[1]+".md";
            }
        }else{
            filename = hostname+"/wiki/"+locations[0]+"/index.md"
        }
    };

    const LinkClick = (e) => {
        e.preventDefault();
        let href = e.target.getAttribute('href');
        window.location.href = href;
        window.location.replace(href);
    };

    LoadPageContent();

    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => {
                        setPageFound(true);
                        setPost(res
                            .replaceAll("<br>", "\n&nbsp;")
                        );
                    });
            })
            .catch(err => {
                setPageFound(false);
                console.log(err);
            });

        import(`../../../../sites/${wikiConfigName}`)
            .then(res => {
                setWikiConfigFound(true);
                setWikiConfig(res.default);
            })
            .catch(err => {
                setWikiConfigFound(false);
                console.log(err);
            });

        import(`../../../../sites/${siteConfigName}`)
            .then(res => {
                setSiteConfigFound(true);
                setSiteConfig(res.default);
            })
            .catch(err => {
                setSiteConfigFound(false);
                console.log(err);
            });
    }, [filename, wikiConfigName, siteConfigName]);
    let pageContent = post;

    if (siteConfig.length === 0 || wikiConfig.length === 0) return (
        <div/>
    );

    let currentPage;

    let loadTimeout = setTimeout(() => {
        if (!wikiConfigFound || !siteConfigFound || !pageFound){
            pageContent = "# Loading Page";
            currentPage = {
                iconType: "fas",
                icon: "spinner",
                title: "Please Wait"
            };
        }
    }, 1000);

    if (!wikiConfigFound || !siteConfigFound || !pageFound){
        pageContent = "# Loading Page";
        currentPage = {
            iconType: "fas",
            icon: "spinner",
            title: "Please Wait"
        };
    }else{
        clearTimeout(loadTimeout);
    }

    let editUrl = "#";
    if (wikiConfigFound && pageFound){
        if (locations.length === 2){
            if (locations[1].length === 0){
                currentPage = wikiConfig[locations[0]];
                editUrl = siteConfig.github+"/tree/master/"+locations[0]+"/index.md";
            }else{
                let parentPage = wikiConfig[locations[0]];
                currentPage = parentPage.subItems[locations[1]];
                currentPage.iconType = parentPage.iconType;
                currentPage.icon = parentPage.icon;
                editUrl = siteConfig.github+"/tree/master/"+locations[0]+"/"+locations[1]+".md";
            }
        }else{
            currentPage = wikiConfig[locations[0]];
            editUrl = siteConfig.github+"/tree/master/"+locations[0]+"/index.md";
        }
    }

    let wikiMenuItems = [];
    let keys = Object.keys(wikiConfig);
    for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = wikiConfig[key];

        let subItems = value.subItems;
        subItems = Object.keys(subItems)
            .filter(key => subItems[key].title.toLowerCase().includes(subMenuSearch.toLowerCase()))
            .reduce((obj, key) => {
                obj[key] = subItems[key];
                return obj;
            }, {});
        let subKeys = Object.keys(subItems);

        let subItemsHtml = [];
        if (subKeys.length !== 0){
            for (let i = 0; i < subKeys.length; i++) {
                let subKey = subKeys[i];
                let subValue = subItems[subKey];

                let classnames = "";
                if(locations[0] === key && locations[1] === subKey){
                    classnames = "active";
                }

                subItemsHtml.push(
                    <a key={'subItem'+key+'/'+subKey} href={siteUrl+'wiki/'+key+'/'+subKey} style={{textDecoration: "none"}} className={classnames}>
                        <div className="subItem">{subValue.title}</div>
                    </a>
                );
            }
        }

        wikiMenuItems.push(
            <div key={'wikiMenuItem'+key} className="wikiMenu">
                <a href={siteUrl+'wiki/'+key} style={{textDecoration: "none"}}>
                    <div className={`wikiMenuItem ${subKeys.length !== 0 ? "hasSubMenu" : ""} ${locations[0] === key ? "active opened" : ""}`}>
                        <div className="icon">
                            <FontAwesomeIcon icon={[value.iconType, value.icon]}/>
                        </div>
                        <div className="text">{value.title}</div>
                    </div>
                </a>
                <div className="hoverSpacer"/>
                <div className={`subItems ${locations[0] === key ? "subItemsBelow" : ""}`}>
                    {value['showSubSearch'] !== undefined && value['showSubSearch'] === true && (
                        <>
                            <div className="subMenuSearch">
                                <input className="subMenuSearchInput" value={subMenuSearch} onChange={(event) => {
                                    setSubMenuSearch(event.target.value);
                                }} type="text" placeholder="Search" />
                            </div>
                            {subKeys.length === 0 && (
                                <div className="noResultsFoundText">No results found</div>
                            )}
                        </>
                    )}
                    {subItemsHtml}
                </div>
            </div>
        );
    }

    let wikiPageNavContentPrev;
    let wikiPageNavContentNext;
    if (currentPage !== undefined){
        let PrevPageData = currentPage.prev;
        let NextPageData = currentPage.next;
        if (PrevPageData !== undefined){
            let PrevPageUrl = PrevPageData.url;
            PrevPageUrl = PrevPageUrl.replaceAll('%origin%', window.location.origin);

            const PrevPage = () => {
                window.location.href = PrevPageUrl;
                window.location.replace(PrevPageUrl);
            };
            if (PrevPageUrl !== "") {
                wikiPageNavContentPrev = (
                    <div className="wikiPrevDiv">
                        <button className="wikiPageNavBtn wikiPrevBtn" onClick={PrevPage}>
                            <div className="wikiPageNavBtnText">
                                <div className="wikiPageNavBtnSubTitle">
                                    Previous
                                </div>
                                <div className="wikiPageNavBtnTitle">
                                    {PrevPageData.title}
                                </div>
                            </div>
                            <div className="wikiPageNavBtnIcon">
                                <FontAwesomeIcon icon={['fas', 'arrow-left']}/>
                            </div>
                        </button>
                    </div>
                );
            }
        }
        if (NextPageData !== undefined){
            let NextPageUrl = NextPageData.url;
            NextPageUrl = NextPageUrl.replaceAll('%origin%', window.location.origin);

            const NextPage = () => {
                window.location.href = NextPageUrl;
                window.location.replace(NextPageUrl);
            };
            if (NextPageUrl !== ""){
                wikiPageNavContentNext = (
                    <div className="wikiPrevDiv">
                        <button className="wikiPageNavBtn wikiNextBtn" onClick={NextPage}>
                            <div className="wikiPageNavBtnText">
                                <div className="wikiPageNavBtnSubTitle">
                                    Next
                                </div>
                                <div className="wikiPageNavBtnTitle">
                                    {NextPageData.title}
                                </div>
                            </div>
                            <div className="wikiPageNavBtnIcon">
                                <FontAwesomeIcon icon={['fas', 'arrow-right']}/>
                            </div>
                        </button>
                    </div>
                );
            }
        }
    }

    const srlOptions = {
        settings: {
            autoplaySpeed: 5000,
            transitionSpeed: 900,
        },
        buttons: {
            showDownloadButton: false
        }
    }

    return (
        <div className="wiki-container">
            <div className="row">
                <div className="colmn-80 wikiContent">
                    <div className="wikiTitle">
                        <div className="icon">
                            <FontAwesomeIcon icon={[currentPage.iconType, currentPage.icon]}/>
                        </div>
                        <span>{currentPage.title}</span>
                    </div>
                    <div className="wikiPageContent">
                        <div className="editButton">
                            <a href={editUrl} target="_blank" rel="noreferrer">Edit <FontAwesomeIcon icon={["fas", "pen"]}/></a>
                        </div>
                        <SRLWrapper options={srlOptions}>
                            <ReactMarkdown
                                children={pageContent}
                                remarkPlugins={
                                    [remarkGfm, remarkBreaks, remarkDirective, Directive, Spoiler, Heading, HexColor]
                                }
                                skipHtml={true}
                                components={{
                                    a({node, children}) {
                                        let href = node.properties.href;
                                        if (href.startsWith('http')){
                                            return (
                                                <a href={href}>{children}</a>
                                            );
                                        }else{
                                            return (
                                                <a href={href} onClick={LinkClick}>{children}</a>
                                            );
                                        }
                                    },
                                    img({node}) {
                                        let src = node.properties.src;
                                        let alt = node.properties.alt;
                                        return (
                                            <span key={src}>
                                                <img src={src} alt={alt}/>
                                            </span>
                                        );
                                    },
                                    code({node, inline, className, children, ...props}) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <div className="code-div" style={{background: theme === themes.dark ? 'rgb(47, 47, 47)' : 'rgb(245, 242, 240)'}}>
                                                <div className="code-copy">
                                                    <CopyToClipboard text={children}
                                                                     onCopy={() => {
                                                                         setCopied(true);
                                                                         notify.success("Copied to clipboard");
                                                                         setTimeout(function (){
                                                                             setCopied(false);
                                                                         }, 1000);
                                                                     }}>
                                                        <FontAwesomeIcon icon={['far', isCopied ? 'clipboard' : 'copy']} style={{color: theme === themes.dark ? 'white' : 'black'}}/>
                                                    </CopyToClipboard>
                                                </div>

                                                <SyntaxHighlighter
                                                    children={String(children).replace(/\n$/, '')}
                                                    style={theme === themes.dark ? PrismStyleDark : PrismStyleLight}
                                                    language={match[1]}
                                                    PreTag="div"
                                                    customStyle={{position: 'revert'}}
                                                    {...props}
                                                />
                                            </div>
                                        ) : (
                                            <code className="color-primary">
                                                {children}
                                            </code>
                                        )
                                    }

                                }}/>
                        </SRLWrapper>
                    </div>
                </div>
                <div className="colmn-20">
                    <div className="wikiMenu" id="navbar">
                        <div className="wikiMenuTitle">
                            <span>Navigation</span>
                        </div>

                        {wikiMenuItems}
                    </div>
                </div>
            </div>

            <div className="wikiPageNav">
                {wikiPageNavContentPrev}
                {wikiPageNavContentNext}
            </div>
        </div>
    );

};

export default Wiki;