import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {PopupElement} from "../../components/popup";

const PageNav = () => {
    let hostname = window.location.hostname;
    let siteUrl = window.location.origin+"/";
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteConfig.length === 0) return (
        <div/>
    );

    let siteName = siteConfig.name;
    let siteIcon = siteConfig.icon.replaceAll('%origin%', window.location.origin);
    let siteNavItems = siteConfig.navItems;

    let navItems = [];

    // if (!siteConfig.pluginSite){
    //     if (window.location.pathname === "/patreon-only"){
    //         let buttonStyle = {
    //             backgroundColor: 'rgba(120, 135, 220, 1)'
    //         };
    //         navItems.push(<a key="patreon-only-plugins" href="./" style={buttonStyle}>Main Plugin</a>);
    //     }else{
    //         let buttonStyle = {
    //             backgroundColor: 'rgb(249, 104, 84)'
    //         };
    //         navItems.push(<a key="main-plugins" href="./patreon-only" style={buttonStyle}>Patreon Plugins</a>);
    //     }
    // }

    // eslint-disable-next-line
    siteNavItems.map(({name, url, color, style, iconType, icon, enabled, popup}) => {
        if(!enabled) return null;

        url = url.replaceAll('%origin%', window.location.origin);
        let buttonStyle = {
            backgroundColor: color
        };
        if(style === "rounded"){
            buttonStyle.border = "1px solid transparent";
            buttonStyle.borderRadius = "5px";
        }
        if(style === "inverted"){
            buttonStyle.backgroundColor = "rgba(0,0,0,0)";
            buttonStyle.color = color;navItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url} style={buttonStyle}>{name}</a>);
        }
        if(style === "icon"){
            buttonStyle.backgroundColor = "rgba(0,0,0,0)";
            buttonStyle.color = color;
        }
        if(url === "popup" && popup !== undefined) {
            navItems.push(
                <PopupElement
                    key={url+name}
                    url={url}
                    name={name}
                    popupData={popup}
                    style={buttonStyle}
                />
            );
        }else{
            if (style === "icon"){
                navItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url} style={buttonStyle}>
                    <FontAwesomeIcon icon={[iconType, icon]} style={{fontSize: "30px"}}/>
                </a>);
            }else{
                navItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url} style={buttonStyle}>{name}</a>);
            }
        }
    });

    return (
        <div className="navbar">
            <div className="icon">
                <img src={siteIcon} alt={siteName}/>
            </div>
            <div className="dropdown">
                <button className="dropBtn">
                    <FontAwesomeIcon icon="bars" />
                </button>
                <div className="buttons">
                    {navItems}
                </div>
            </div>
        </div>
    );
}

export default PageNav;