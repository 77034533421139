import React from "react";

class PageNotFound extends React.Component {
    render() {
        return (
            <div className="PageNotFound">
                <h1>404</h1>
                <p>Page not found</p>
                <a href="./">Back to home</a>
            </div>
        );
    }
}

export default PageNotFound;