import React, {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FsLightbox from 'fslightbox-react';
import TestFlaskLight from "../../assets/img/TestFlaskLight.png";
import TestFlaskDark from "../../assets/img/TestFlaskDark.png";
import {themes} from "../../contexts/ThemeContext";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

const PluginOverview = ({theme}) => {
    let hostname = window.location.hostname;
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);
    const [isVisible, setIsVisible] = useState(false)
    const [slide, setSlide] = useState(0);

    const showSlide = (index) => {
        setIsVisible(!isVisible);
        setSlide(index);
    }

    if (siteConfig.length === 0) return (
        <div/>
    );

    let siteDescription = siteConfig.description;
    let siteGifs = siteConfig.gifs;
    let siteFeatures = siteConfig.features;

    let gifItems = [];
    let gifItemSources = [];
    // eslint-disable-next-line
    let gifsCount = 0;
    let itemCount = siteGifs.length;

    siteGifs.map((gif, index) => {
        let lore = gif.lore;
        let type = gif.type ?? 'gif';

        if(type === "gif"){
            gifsCount++;

            let classNames = 'gif ';
            if (Math.abs(gifsCount % 2) === 1 && index === ( itemCount - 1 )){
                classNames += 'gif-span2';
            }

            let url = gif.url.replaceAll('%origin%', window.location.origin);
            if (url.endsWith('jpg') || url.endsWith('jpeg') || url.endsWith('png') || url.endsWith('gif')){
                gifItems.push(
                    <div key={'overviewImg'+index} className={classNames} onClick={() => showSlide(index+1)}>
                        <img src={url} alt={lore}/>
                    </div>);
                gifItemSources.push(
                    <div className="gifLightBox">
                        <img src={url} style={{width: '80vh', aspectRatio: '16/9'}} alt={lore} />
                        <div className="gifLightBoxLore">{lore}</div>
                    </div>
                );
            }else {
                gifItems.push(
                    <div key={'overviewVideo'+index} className={classNames} onClick={() => showSlide(index+1)}>
                        <video
                            style={{width: '100%'}}
                            autoPlay
                            muted
                            loop
                        >
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>);
                gifItemSources.push(
                    <div className="gifLightBox">
                        <video
                            style={{width: '80vh', aspectRatio: '16/9'}}
                            controls
                            controlsList="nodownload noremoteplayback noplaybackrate"
                            disablePictureInPicture
                            disableRemotePlayback
                            autoPlay
                            muted
                            loop
                        >
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className="gifLightBoxLore">{lore}</div>
                    </div>
                );
            }
        }else if(type === "heading"){
            let classNames = 'heading';
            gifItems.push(
                <div key={'overviewHeading'+index} className={classNames}>
                    <div className="line"></div>
                    <h2 className="lore">{lore}</h2>
                    <div className="line"></div>
                </div>);
        }
    });

    let featureItems = [];
    // eslint-disable-next-line
    siteFeatures.map(({iconType, icon, name, text}) => {
        featureItems.push(
            <div key={icon+name} className="feature">
                <div className="icon">
                    <FontAwesomeIcon icon={[iconType, icon]}/>
                </div>
                <div className="title">
                    <h3>{name}</h3>
                </div>
                <div className="desc">{text}</div>
            </div>);
    });

    let descStyle = {};
    if (siteDescription.length === 0){
        descStyle.margin = 0;
    }
    let gifsStyle = {};
    if (gifItems.length === 0){
        gifsStyle.margin = 0;
    }
    let featuresStyle = {};
    if (featureItems.length === 0){
        featuresStyle.margin = 0;
    }

    function fallbackCopyTextToClipboard(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    }

    hostname = "ultraeconomy.com";

    return (
        <div className="overview">
            <div className="description" style={descStyle}>
                <ReactMarkdown
                    children={siteDescription}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        code({node, inline, className, children, ...props}) {
                            const match = /language-(\w+)/.exec(className || '')
                            return inline && !match ? (
                                <span className="color-primary">
                                    {children}
                                </span>
                            ) : (
                                <span className="color-primary">
                                    {children}
                                </span>
                            )
                        }
                    }}/>
            </div>

            { (siteConfig.showTestServerBanner !== undefined && siteConfig.showTestServerBanner !== false) && (
                    <div className="test-server-container">
                        <div className="icon">
                            <img src={theme === themes.dark ? TestFlaskLight : TestFlaskDark} alt="ICON"/>
                        </div>
                        <div className="contents">
                            <div className="text">
                                <h1>Test {siteConfig.name}</h1>
                                <p>We provide test servers for all our plugins. <br/> Join now and test {siteConfig.name} plugin now.</p>
                            </div>
                            <div className="info-text">
                                <div className="input-icon">
                                    <input
                                        className="copy-click-input"
                                        onClick={() => {
                                            copyTextToClipboard(`test.techscode.com`);
                                        }}
                                        type="text"
                                        value={`test.techscode.com`}
                                        readOnly={true}
                                    />
                                    <div className="copy-icon">
                                        <FontAwesomeIcon icon={faCopy}/>
                                    </div>
                                </div>
                                <p>Minecraft 1.8.x - 1.19.x</p>
                            </div>
                        </div>
                    </div>
            )}

            <div className="gifs" style={gifsStyle}>
                <FsLightbox
                    toggler={isVisible}
                    slide={slide}
                    sources={gifItemSources}
                />
                {gifItems}
            </div>
            <div className="features" style={featuresStyle}>
                {featureItems}
            </div>
        </div>
    );
}

export default PluginOverview;