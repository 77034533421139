import React, {useState} from "react";
import ServersChart from "../chartPageComponents/ServersChart";
import PlayersChart from "../chartPageComponents/PlayersChart";
import McVersionPie from "../chartPageComponents/McVersionPie";
import McPlatformPie from "../chartPageComponents/McPlatformPie";

const BStats = ({config}) => {
    const bStatsId = config.bStatsId ?? 0;
    const [serversBadgeCurrent, setServersBadgeCurrent] = useState(0);
    const [serversBadgeRecord, setServersBadgeRecord] = useState(0);
    const [playersBadgeCurrent, setPlayersBadgeCurrent] = useState(0);
    const [playersBadgeRecord, setPlayersBadgeRecord] = useState(0);

    return (
        <div className="bstats">
            {bStatsId > 0 && (
                <>
                    <h1>Plugin statistics</h1>
                    <div className="headlines">
                        <div className="headline servers">
                            <div className="icon">
                                <i className="fa-solid fa-server"></i>
                            </div>
                            Servers (Current/Record): {serversBadgeCurrent} / {serversBadgeRecord}
                        </div>
                        <div className="headline players">
                            <div className="icon">
                                <i className="fa-solid fa-users"></i>
                            </div>
                            Players (Current/Record): {playersBadgeCurrent} / {playersBadgeRecord}
                        </div>
                    </div>

                    <div className="charts">
                        <ServersChart
                            id={bStatsId}
                            current={setServersBadgeCurrent}
                            record={setServersBadgeRecord}
                        />
                        <PlayersChart
                            id={bStatsId}
                            current={setPlayersBadgeCurrent}
                            record={setPlayersBadgeRecord}
                        />
                    </div>
                    <div className="pies-row">
                        <McVersionPie id={bStatsId}/>
                        <McPlatformPie id={bStatsId}/>
                    </div>

                    <p>View more details <a href={`https://bstats.org/plugin/bukkit/plugin/${bStatsId}`} target="_blank">here</a>.</p>
                    <p>Data provided by <a href="https://bstats.org" target="_blank">bstats.org</a>.</p>
                </>
            )}
            {bStatsId === 0 && (
                <h1>bStats is not enabled for this plugin!</h1>
            )}
        </div>
    );
}

export default BStats;