import {visit} from 'unist-util-visit';

function prepend(value, array) {
    let newArray = array.slice();
    newArray.unshift(value);
    return newArray;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function Directive() {
    return (tree) => {
        visit(tree, (node) => {
            if (
                node.type === 'containerDirective'
            ) {
                if (node.name !== 'error' && node.name !== 'success' && node.name !== 'warning' && node.name !== 'info') return

                const data = node.data || (node.data = {});
                data.hName = 'div'
                data.hProperties = {
                    className: "card "+node.name
                }

                if (node.children.length < 2){
                    node.children = prepend({
                        type: 'heading',
                        depth: 1,
                        children: [{
                            type: 'text',
                            value: capitalizeFirstLetter(node.name)
                        }]
                    }, node.children);
                }

            }
        })
    }
}

export default Directive;