import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import SimpleReactLightbox from 'simple-react-lightbox';

library.add(fab, fas, far);

ReactDOM.render(
    <BrowserRouter>
        <SimpleReactLightbox>
            <App />
        </SimpleReactLightbox>
    </BrowserRouter>,
    document.getElementById("root")
);