import React, {useEffect, useState} from "react";
import FooterLogoDark from '../../assets/img/TechsCodeLogo.png';
import FooterLogoLight from '../../assets/img/TechsCodeLogoLight.png';
import {themes} from "../../contexts/ThemeContext";

const PageFooter = ({theme}) => {
    let hostname = window.location.hostname;
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');

    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteConfig.length === 0) return (
        <div/>
    );

    let footerMenuItems = siteConfig.footerMenu;

    let navItems = [];
    // eslint-disable-next-line
    footerMenuItems.map(({name, url, color}) => {
        url = url.replaceAll('%origin%', window.location.origin);
        name = name.replaceAll('%year%', new Date().getFullYear());
        if (url == null || url === ""){
            navItems.push(<span key={url+name} style={{backgroundColor: color}}>{name}</span>);
        }else{
            navItems.push(<a key={url+name} href={url} style={{backgroundColor: color}}>{name}</a>);
        }
    });

    return (
        <div className="footer">
            <div className="logo">
                <img src={theme === themes.dark ? FooterLogoLight : FooterLogoDark} alt=""/>
            </div>
            <div className="nav">
                {navItems}
            </div>
        </div>
    );
}

export default PageFooter;