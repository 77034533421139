import React, {useEffect, useState} from "react";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import remarkDirective from "remark-directive";
import Directive from "../../customRemarkPlugins/directive";
import {Prism as SyntaxHighlighter} from "react-syntax-highlighter";
import {prism as PrismStyle} from "react-syntax-highlighter/dist/cjs/styles/prism";
import ReactMarkdown from "react-markdown";
import moment from 'moment';

const Updates = () => {
    let hostname = window.location.hostname;
    const filename = hostname+"/updates.json";
    const [siteUpdates, setUpdates] = useState([]);
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                let updates = res.default;

                updates.sort((a,b) => {
                    a = a.version.split(".");
                    b = b.version.split(".");
                    return a[0]-b[0] || a[1]-b[1] || a[2]-b[2] || a[3]-b[3];
                });

                setUpdates(updates);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteUpdates.length === 0) return (
        <div/>
    );

    let updateItems = [];
    siteUpdates.forEach(update => {
            let unixDate = new Date(update.date);
            let updateDate = moment(unixDate).format("MMMM DD, Y");

            updateItems.push(
                <div className="update" key={update.date}>
                    <div className="header">
                        <div className="headerTitle">
                            <div className="version">
                                {update.version}
                            </div>
                            <div className="title">
                                {update.title}
                            </div>
                        </div>
                        <div className="date">
                            {updateDate}
                        </div>
                    </div>
                    <div className="body">
                        <ReactMarkdown
                            children={update.content}
                            remarkPlugins={
                                [remarkGfm, remarkBreaks, remarkDirective, Directive]
                            }
                            skipHtml={false}
                            components={{
                                code({node, inline, className, children, ...props}) {
                                    const match = /language-(\w+)/.exec(className || '')
                                    return !inline && match ? (
                                        <div className="code-div">
                                            <SyntaxHighlighter
                                                children={String(children).replace(/\n$/, '')}
                                                style={PrismStyle}
                                                language={match[1]}
                                                PreTag="div"
                                                customStyle=""
                                                {...props}
                                            />
                                        </div>
                                    ) : (
                                        <code className="color-primary">
                                            {children}
                                        </code>
                                    )
                                }
                            }}/>
                    </div>
                </div>
            );
        });

    updateItems = updateItems.reverse();

    return (
        <div className="updates">
            {updateItems}
        </div>
    );
}

export default Updates;