import React, {useEffect, useState} from "react";

const MainOverview = () => {
    let hostname = window.location.hostname;
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteConfig.length === 0) return (
        <div/>
    );

    let pluginBanners = siteConfig.pluginBanners;
    if (window.location.pathname === "/patreon-only"){
        pluginBanners = siteConfig.patreonPluginBanners;
    }

    let bannerItems = [];
    // eslint-disable-next-line
    pluginBanners.map(({logo, price, banner, description, link, color}) => {
        logo = logo.replaceAll('%origin%', window.location.origin);
        banner = banner.replaceAll('%origin%', window.location.origin);

        if(typeof price === "number"){
            price = parseFloat(price).toFixed(2) + " €";
        }

        let gradient = {
            background: "linear-gradient(0deg, "+color+" 0%, rgba(255,255,255,0) 50%)"
        };
        let textBgColor = {
            background: color
        };

        bannerItems.push(
            <div key={link+price} className="pluginBanner">
                <a href={link}>
                    <img src={logo} className="logo" alt="logo"/>
                    <p className="price">{price}</p>
                    <div className="bannerPlugin">
                        <img src={banner} alt="banner"/>
                    </div>
                    <div className="gradiant" style={gradient}/>
                    <div className="text" style={textBgColor}>
                        {description}
                    </div>
                </a>
            </div>
        );
    });

    let bannersStyle = {};
    if (bannerItems.length === 0){
        bannersStyle.margin = 0;
    }

    return (
        <div className="overview">
            {/*<div className="announcement-container">*/}
            {/*    <div className="icon">*/}
            {/*        <img src="https://insanevaults.com/images/logo.png" alt="LOGO"/>*/}
            {/*    </div>*/}
            {/*    <div className="text">*/}
            {/*        <h1>Pre-Order InsaneVaults now!</h1>*/}
            {/*        <p>InsaneVaults is now available for preorder!</p>*/}
            {/*    </div>*/}
            {/*    <div className="button">*/}
            {/*        <button onClick={() => {*/}
            {/*            window.open("https://preorder.insanevaults.com/").focus();*/}
            {/*        }} >Pre-Order Now</button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="pluginBanners">
                {bannerItems}
            </div>
        </div>
    );
}

export default MainOverview;