import React, {useEffect, useState} from "react";
import {PopupElement} from "../../components/popup";

const PageMenu = () => {
    let hostname = window.location.hostname;
    let siteUrl = window.location.origin+"/";
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteConfig.length === 0) return (
        <div/>
    );

    let siteMenuItems = siteConfig.menuItems;
    // eslint-disable-next-line
    const currentPage = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');

    let menuItems = [];
    // eslint-disable-next-line
    siteMenuItems.map(({name, url, textColorOverwrite, enabled, popup}) => {
        if(!enabled) return null;
        if(url === "popup" && popup !== undefined) {
            menuItems.push(
                <PopupElement
                    key={url+name}
                    url={url}
                    name={name}
                    popupData={popup}
                />
            );
        }else{
            url = url.replaceAll('%origin%', window.location.origin);
            if (url.split("/")[0] === currentPage) {
                if (textColorOverwrite) {
                    menuItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url} className="active" style={{color: textColorOverwrite}}>{name}</a>);
                } else {
                    menuItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url} className="active">{name}</a>);
                }
            } else {
                menuItems.push(<a key={url+name} href={url.startsWith("http") ? url : siteUrl+url}>{name}</a>);
            }
        }
    });

    if (menuItems.length === 0){
        return (
            <div/>
        );
    }

    return (
        <>
            <div className="menu">
                {menuItems}
            </div>

            {/*<div className="announcement-container">*/}
            {/*    <div className="icon">*/}
            {/*        <img src="https://insanevaults.com/images/logo.png" alt="LOGO"/>*/}
            {/*    </div>*/}
            {/*    <div className="text">*/}
            {/*        <h1>Pre-Order InsaneVaults now!</h1>*/}
            {/*        <p>InsaneVaults is now available for preorder!</p>*/}
            {/*    </div>*/}
            {/*    <div className="button">*/}
            {/*        <button onClick={() => {*/}
            {/*            window.open("https://preorder.insanevaults.com/").focus();*/}
            {/*        }} >Pre-Order Now</button>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
}

export default PageMenu;