import React, {useEffect, useState} from "react";
import {Pie, PieChart, Tooltip} from "recharts";

const McPlatformPie = ({id}) => {
    const [data, setData] = useState([]);

    let availableColors = [
        "#ff0000",
        "#0011ff",
        "#00ff22",
        "#ff00ee",
        "#fbff00",
        "#00fff2",
        "#ff0080",
        "#7300ff",
        "#0073ff",
        "#00ffb3",
        "#91ff00",
        "#ff9100",
        "#ff5e00",
        "#ff6969",
        "#6975ff",
        "#69ff7a",
        "#fa69ff",
        "#fffa69",
        "#69fff7",
        "#ff69af",
        "#a569ff",
        "#69a5ff",
        "#6affc2",
        "#c2ff69",
        "#ffaf69",
        "#ff6a6a",
    ];

    useEffect(() => {
        if (id !== 0) {
            let url = `https://bstats.org/api/v1/plugins/${id}/charts/serverSoftware/data`;
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    data = data.sort((a, b) => b.y - a.y);
                    data = data.slice(0, 10);
                    data.forEach((element, index) => {
                        element.fill = availableColors[index];
                        element.percentage = (element.y / data.reduce((a, b) => a + b.y, 0) * 100).toFixed(1);
                    });
                    setData(data);
                });
        }
    }, []);

    return (
        <div className="pie">
            <div className="pie-title">Minecraft Platform</div>
            <div className="pie-content">
                <PieChart width={150} height={300}>
                    <Pie
                        data={data}
                        dataKey="y"
                        cx="50%"
                        cy="50%"
                        outerRadius={60}
                    />
                    <Tooltip
                        wrapperClassName="chart-tooltip"
                        content={(props) => {
                            if (props.active) {
                                let item = props.payload[0].payload;
                                return (
                                    <div className="chart-tooltip">
                                        <span className="tooltip-title">{item.name}</span>
                                        <span className="tooltip-percentage"><strong>Share:</strong> {item.percentage}%</span>
                                        <span className="tooltip-value"><strong>Total:</strong> {item.y}</span>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                </PieChart>
                <div className="pie-labels">
                    {data.map((entry, index) => (
                        <div key={index} className="pie-label">
                            <div className="chart-label-color" style={{backgroundColor: entry.fill}}/>
                            <div className="chart-label-text">{entry.name} ({entry.percentage}%)</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default McPlatformPie;