import {visit} from 'unist-util-visit';

function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

function Spoiler() {
    return (tree) => {
        visit(tree, (node) => {
            if (node.type === 'containerDirective') {
                if (node.name !== 'spoiler') return

                const data = node.data || (node.data = {});
                data.hName = 'div'
                data.hProperties = {
                    className: "spoiler",
                    id: uuidv4(),
                    onclick: () => {
                        const spoiler = document.getElementById(data.hProperties.id);
                        if (!spoiler.classList.contains('spoiler-open')) {
                            spoiler.classList.add('spoiler-open');
                        }
                    }
                }
            }
        })
    }
}

export default Spoiler;