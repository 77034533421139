import React from "react";

const Discord = () => {
    return (
        <div className="discord">
            <iframe title="TechsCode Discord" src="https://discord.com/widget?id=311178000026566658&theme=dark" height="500"
                    allowTransparency="true" frameBorder="0"
                    sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
    );
}

export default Discord;