import React, {useEffect, useState} from "react";
import {CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";

const ServersChart = ({id, current, record}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (id !== 0) {
            let url = `https://bstats.org/api/v1/plugins/${id}/charts/servers/data?maxElements=1000`;
            fetch(url)
                .then(response => response.json())
                .then(rawData => {
                    let newData = [];
                    rawData.forEach((item) => {
                        let date = new Date(item[0]);
                        let day = date.getDate();
                        let month = date.getMonth() + 1;
                        let year = date.getFullYear();

                        newData.push({
                            time: `${day}/${month}/${year}`,
                            value: item[1]
                        });
                    });

                    let currentValue = newData[newData.length - 1].value;
                    let recordValue = 0;
                    for (let i = 0; i < newData.length; i++) {
                        recordValue = newData[i].value > recordValue ? newData[i].value : recordValue;
                    }

                    if (current !== undefined) {
                        current(currentValue);
                    }
                    if (record !== undefined) {
                        record(recordValue);
                    }

                    setData(newData);
                });
        }
    }, []);

    let chartWidth = 600;
    if (window.innerWidth < 600) {
        chartWidth = window.innerWidth - 20;
    }

    return (
        <div className="chart">
            <div className="chart-title">Servers</div>
            <div className="chart-content">
                <LineChart width={chartWidth} height={300} data={data}>
                    <Line connectNulls dataKey="value" stroke="var(--bStatsChartLineColor)" />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis dataKey="time" />
                    <YAxis dataKey="value" />
                    <Tooltip
                        wrapperClassName="chart-tooltip"
                        content={(props) => {
                            if (props.active) {
                                let item = props.payload[0].payload;
                                let value = item.value.toLocaleString({
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                });
                                return (
                                    <div className="chart-tooltip">
                                        <span className="tooltip-value">Servers: <strong>{value}</strong></span>
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                </LineChart>
            </div>
        </div>
    );
}

export default ServersChart;