import React, {useEffect, useState} from "react";

const PageBanner = () => {
    let hostname = window.location.hostname;
    const filename = hostname+"/config.json";
    const [siteConfig, setConfig] = useState('');
    useEffect(() => {
        import(`../../../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename]);

    if (siteConfig.length === 0) return (
        <div/>
    );
    
    let siteName = siteConfig.name;
    let siteBanner = siteConfig.banner;

    if (siteBanner == null || siteBanner === ""){
        return (
            <div/>
        );
    }

    siteBanner = siteBanner.replaceAll('%origin%', window.location.origin);

    return (
        <div className="banner">
            <img src={siteBanner} alt={siteName}/>
        </div>
    );
}

export default PageBanner;