import Popup from "reactjs-popup";

export const PopupElement = (props) => {
    let url = props.url;
    let name = props.name;
    let popupData = props.popupData;
    let buttonStyle = props.style ?? {};

    let title = popupData.title
    let text = popupData.text;
    let actions = popupData.actions;
    let actionsVertical = actions.vertical;
    let actionLinks = actions.links;
    let actionLinkEls = [];

    actionLinks.forEach(button => {
        let buttonUrl = button.url;
        let buttonText = button.text;
        let buttonColor = button.color ?? "";
        let buttonColorHover = button.colorHover ?? "";
        let buttonBorderColor = button.borderColor ?? "";
        let buttonBorderColorHover = button.borderColorHover ?? "";
        let buttonTextColor = button.textColor ?? "";
        let buttonTextColorHover = button.textColorHover ?? "";
        let buttonImage = button.image ?? "";

        let style = {};
        if(buttonColor !== "") {
            style['--buttonColor'] = buttonColor;
        }
        if(buttonColorHover !== "") {
            style['--buttonColorHover'] = buttonColorHover;
        }
        if(buttonBorderColor !== "") {
            style['--buttonBorderColor'] = buttonBorderColor;
        }
        if(buttonBorderColorHover !== "") {
            style['--buttonBorderColorHover'] = buttonBorderColorHover;
        }
        if(buttonTextColor !== "") {
            style['--buttonTextColor'] = buttonTextColor;
        }
        if(buttonTextColorHover !== "") {
            style['--buttonTextColorHover'] = buttonTextColorHover;
        }

        let buttonImageEl;
        if(buttonImage !== "") {
            buttonImageEl = (
                <img src={buttonImage} alt={buttonText} />
            );
        }
        actionLinkEls.push(<a target='_blank' style={style} className="action-button" key={buttonUrl+buttonText} href={buttonUrl}>{buttonImageEl ? buttonImageEl : ""}{buttonText}</a>)
    });

    return (
        <Popup key={url+name} trigger={
            <span style={buttonStyle}>{name}</span>
        } position="center" modal nested>
            {close => (
                <div className="modal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header">{title}</div>
                    <div className="content">{text}</div>
                    <div className={`actions${actionsVertical ? " actions-vert" : ""}`}>
                        {actionLinkEls}
                    </div>
                </div>
            )}
        </Popup>
    );
}