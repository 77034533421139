import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {Helmet} from "react-helmet";
import ThemeSwitcher from 'react-css-vars';
import {themes} from "./contexts/ThemeContext";
import useLocalStorage from "./hooks/useLocalStorage";
import { initFirebase, setValue, listenForValueChange } from "./firebase";

import PageNav from "./pages/layout/nav";
import PageMenu from "./pages/layout/menu";
import PageBanner from "./pages/layout/banner";
import PageFooter from "./pages/layout/footer";
import Custom from "./pages/custom";
import Discord from "./pages/discord";
import Wiki from "./pages/pluginSite/wiki";
import Updates from "./pages/pluginSite/updates";
import PluginOverview from "./pages/pluginSite/overview";
import MainOverview from "./pages/mainPage/overview";
import PageNotFound from "./pages/404";
import './assets/css/style.css';
import '../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css'
import '../node_modules/@fortawesome/fontawesome-free/css/solid.css'
import '../node_modules/@fortawesome/fontawesome-free/css/regular.css'
import 'reactjs-popup/dist/index.css';
import BStats from "./pages/bstats";

function App() {
    let hostname = window.location.hostname;
    let origin = window.location.origin;

    const getDeviceId = () => {
        let navigator_info = window.navigator;
        let screen_info = window.screen;
        let uid = navigator_info.mimeTypes.length;
        uid += navigator_info.userAgent.replace(/\D+/g, '');
        uid += navigator_info.plugins.length;
        uid += screen_info.height || '';
        uid += screen_info.width || '';
        uid += screen_info.pixelDepth || '';
        return uid;
    }

    const filename = hostname + "/config.json";
    const [siteConfig, setConfig] = useState([]);
    const [theme, setTheme] = useLocalStorage("theme", "light");
    const database = initFirebase();
    const databaseKey = "deviceThemeConfig/"+getDeviceId();

    useEffect(() => {

        const themeChanged = (key, data) => {
            if(data == null || data['theme'] == null){
                return;
            }
            setTheme(data['theme']);
        };
        listenForValueChange(database, databaseKey, themeChanged);

        import(`../../sites/${filename}`)
            .then(res => {
                setConfig(res.default);
            })
            .catch(err => console.log(err));
    }, [filename, hostname, origin, setTheme, theme, database, databaseKey]);

    let htmlData = (<></>);

    if (window.location.pathname === '/404') {
        htmlData = (<PageNotFound/>);
    }

    if (siteConfig.length !== 0) {
        let siteIcon = siteConfig.icon.replaceAll('%origin%', window.location.origin);
        let colors = siteConfig.colors[theme];

        const toggleTheme = () => {
            let newTheme = theme === themes.dark ? themes.light : themes.dark;
            setTheme(newTheme);

            setValue(database, databaseKey, {
                "theme": newTheme,
            });
        };

        const siteTheme = {
            primary: colors.primary,
            background: colors.background,
            container: colors.container,
            textColor: colors.textColor,
            wikiNoteBorder: colors.wikiNoteBorder,
            wikiNoteBg: colors.wikiNoteBg,
            wikiButtonBg: colors.wikiButtonBg,
            wikiButtonText: colors.wikiButtonText,
            wikiButtonHoverBg: colors.wikiButtonHoverBg,
            wikiButtonHoverText: colors.wikiButtonHoverText,
            wikiButtonActiveBg: colors.wikiButtonActiveBg,
            wikiButtonActiveText: colors.wikiButtonActiveText,
            wikiButtonActiveHoverBg: colors.wikiButtonActiveHoverBg,
            wikiButtonActiveHoverText: colors.wikiButtonActiveHoverText,
            wikiPageContentBg: colors.wikiPageContentBg,
            wikiPageContentTitle: colors.wikiPageContentTitle,
            wikiPageContentText: colors.wikiPageContentText,
            wikiPageContentLink: colors.wikiPageContentLink,
            footerText: colors.footerText,
            footerTextHover: colors.footerTextHover,
            featureTitleColor: colors.featureTitleColor,
            featureTextColor: colors.featureTextColor,
            bStatsHeadlineBg: colors.bStatsHeadlineBg,
            bStatsHeadlineText: colors.bStatsHeadlineText,
            bStatsChartLineColor: colors.bStatsChartLineColor || "rgba(131, 52, 235, 1)"
        };

        if (siteConfig.pluginSite) {
            htmlData = (
                <div>
                    <ThemeSwitcher theme={siteTheme}/>
                    <Helmet>
                        <meta charSet="utf-8"/>
                    </Helmet>

                    <button className={"themeToggleBtn theme-" + theme} onClick={toggleTheme}>
                        {
                            theme === themes.dark ?
                                (<i className="fa-regular fa-sun theme-icon-light"></i>) :
                                (<i className="fa-regular fa-moon theme-icon-dark"></i>)
                        }
                    </button>

                    <div className="outer-container">
                        <div className="inner-container">
                            <div className="content-container">
                                <PageNav/>
                                <PageBanner/>
                                <PageMenu/>

                                <Routes>
                                    <Route path="/" element={<PluginOverview theme={theme}/>}/>
                                    <Route path="/updates" element={<Updates/>}/>
                                    <Route path="/wiki/*" element={<Wiki theme={theme}/>}/>
                                    <Route path="/discord" element={<Discord/>}/>
                                    <Route path="/bstats" element={<BStats config={siteConfig}/>}/>
                                    <Route path="/*" element={<Custom/>}/>
                                </Routes>
                            </div>
                            <PageFooter theme={theme}/>
                        </div>
                    </div>
                </div>
            );
        } else {
            htmlData = (
                <div>
                    <ThemeSwitcher theme={siteTheme}/>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <link rel="icon" href={siteIcon}/>
                    </Helmet>

                    <button className={"themeToggleBtn theme-" + theme} onClick={toggleTheme}>
                        {
                            theme === themes.dark ?
                                (<i className="fa-regular fa-sun theme-icon-light"></i>) :
                                (<i className="fa-regular fa-moon theme-icon-dark"></i>)
                        }
                    </button>

                    <div className="outer-container">
                        <div className="inner-container">
                            <div className="content-container">
                                <PageNav/>
                                <Routes>
                                    <Route path="/" element={<MainOverview/>}/>
                                    <Route path="/patreon-only" element={<MainOverview/>}/>
                                    <Route path="/discord" element={<Discord/>}/>
                                    <Route path="/*" element={<Custom/>}/>
                                </Routes>
                            </div>
                            <PageFooter theme={theme}/>
                        </div>
                    </div>
                    <div id="popup-root" />
                </div>
            );
        }
    }

    return htmlData;
}

export default App;
