import { visit } from 'unist-util-visit';

function HexColor() {
    return (node) => {
        visit(node, 'element', node => {
            let value = node.value;
            if(value === null){
                return;
            }

            let colorMatch = value.match(/<#[\da-fA-F]{6}>/ig);
            if(colorMatch === null){
                return;
            }
            let color = colorMatch[0].replace('<', '').replace('>', '');

            value = value.replaceAll(/<#[\da-fA-F]{6}>/ig, ``)
            value = value.replaceAll(/<\/#>/ig, '');

            if (!node.data) {
                node.data = {}
            }
            let data = node.data;
            data.color = color;

            //console.log(node);

            node.value = value;
        });
    }
}

export default HexColor;