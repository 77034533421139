import React, {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {prism as PrismStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkDirective from 'remark-directive';
import remarkBreaks from 'remark-breaks';
import Directive from '../customRemarkPlugins/directive';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notyf } from 'notyf';
import remarkGfm from 'remark-gfm'
import 'notyf/notyf.min.css';
import Heading from "../customRemarkPlugins/heading";

const notyf = new Notyf({
    duration: 5000,
    position: {x: 'right', y: 'top'},
    dismissible: true,
    ripple: true
});

let hostname = window.location.hostname;
const Custom = () => {
    const [isCopied, setCopied] = useState(false);
    const [post, setPost] = useState('');
    const [pageFound, setPageFound] = useState(false);

    const location = useLocation().pathname;
    let locations = location.split("/");

    let filename = hostname+"/"+locations[0].replace("/", "")+locations[1]+".md";

    useEffect(() => {
        if(locations[1] === hostname){
            return;
        }

        import(`../../../sites/${filename}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => {
                        setPageFound(true);
                        setPost(res
                            .replaceAll("<br>", "\n&nbsp;")
                        );
                    });
            })
            .catch(err => {
                setPageFound(false);
                console.log(err);
            });
    }, [filename, locations]);
    let pageContent = post;

    if (!pageFound){
        pageContent = "# Page not found";
    }

    return (
        <div className="custom-page">
            <ReactMarkdown
                children={pageContent}
                remarkPlugins={
                    [remarkGfm, remarkBreaks, remarkDirective, Directive, Heading]
                }
                skipHtml={false}
                components={{
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <div className="code-div">
                                <div className="code-copy">
                                    <CopyToClipboard text={children}
                                                     onCopy={() => {
                                                         setCopied(true);
                                                         notyf.success("Copied to clipboard");
                                                         setTimeout(function (){
                                                             setCopied(false);
                                                         }, 1000);
                                                     }}>
                                        <FontAwesomeIcon icon={['far', isCopied ? 'clipboard' : 'copy']}/>
                                    </CopyToClipboard>
                                </div>

                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={PrismStyle}
                                    language={match[1]}
                                    PreTag="div"
                                    customStyle=""
                                    {...props}
                                />
                            </div>
                        ) : (
                            <code className="color-primary">
                                {children}
                            </code>
                        )
                    }
                }}/>
        </div>
    );

};

export default Custom;