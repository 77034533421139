import {initializeApp} from "firebase/app";
import {getDatabase, ref, onValue, set } from "firebase/database";

export const initFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyDd7kSjnShouvxrg5_0NsY8irD0wSXRIek",
        authDomain: "techscodewikis.firebaseapp.com",
        projectId: "techscodewikis",
        storageBucket: "techscodewikis.appspot.com",
        messagingSenderId: "676647938730",
        appId: "1:676647938730:web:f74ef3bc33e7e0f748bed3",
        measurementId: "G-MENHE5KGJP",
        databaseURL: "https://techscodewikis-default-rtdb.europe-west1.firebasedatabase.app"
    };

    const app = initializeApp(firebaseConfig);
    return getDatabase(app);
}

export const setValue = (database, key, value) => {
    set(ref(database, key), value).then(r => {
        if (r) {
            console.log(r)
        }
    });
}

export const listenForValueChange = (database, key, callback) => {
    const dbRef = ref(database, key);
    onValue(dbRef, snapshot => {
        const data = snapshot.val();
        callback(key, data);
    });
}